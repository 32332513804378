#footer {
    color: #444444;
    font-size: 14px;
    background: #f1f7fd;
}

#footer .footer-top {
    padding: 60px 0 30px 0;
    background: #fff;
    box-shadow: 0px 2px 15px rgb(25 119 204 / 10%);
}



#footer .footer-top i {
    color: #1977cc;
/*     padding-right: 4px;
    margin-left: 15px;
    line-height: 0; */
    
}

#footer .footer-top a {
    color: #19cc58;
    text-decoration: none;
}
.py-4 {
    padding-top: 1.5rem!important;
    padding-bottom: 1.5rem!important;
}
