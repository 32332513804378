
/*--------------------------------------------------------------
# servicio 
--------------------------------------------------------------*/
.servicio {
	background: #fff;
	padding-top: 20px;
	
  }
  
  
.servicio .servicio-title {
	text-align: center;
	padding-bottom: 30px;
 } 

 .servicio .servicio-title h2 {
	font-size: 32px;
	font-weight: bold;
	margin-bottom: 20px;
	padding-bottom: 20px;
	position: relative;
	color: #2c4964;
 }

.servicio .list1 {
	overflow: hidden;
	position: relative;
	list-style: none;
	margin-bottom: 40px;
	text-align: center;

}


.servicio .list1 .img-circle {
	position: relative;
	width: 170px;
	height: 170px;
	margin: 0 auto 56px;
	background: #7ecefd;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;

}

.servicio .list1 .img-circle span {
	position: absolute;
	border-radius: 500px;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0px solid #7ecefd;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.servicio .list1:hover .img-circle {
	background: #16a3f5;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.servicio .list1:hover .img-circle span {
	position: absolute;
	border-radius: 150px;
	top: 0;
	left: 0;
	border: 10px solid #7ecefd;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}


.servicio .list1  p {
	color: #bab9b9;
	margin-bottom: 37px;
	
}


.servicio .listtitle1 {
	text-align: center;
	position: relative;
	display: block;
	text-transform: uppercase;
	font-family: 'Open Sans', sans-serif;
	font-size: 30px;
	line-height: 30px;
	font-weight: 300;
	color: #3e454c !important;
	padding: 0;
	margin: 0 0 20px !important;
}
.servicio .listtitle1 span {
	display: block;
	color: #7ecefd;
	font-family: 'Open Sans', sans-serif;
	font-size: 18px;
	line-height: 20px;
	font-weight: 600;
	margin-top: 3px;
}

.servicio .img-circle {
	border-radius: 150px;
}

.img-circle {
	border-radius: 150px;
}

/* 
*/
/* .servicio {
	padding: 0;
	border: 5px solid rgb(171, 174, 31);

} */

