
/*--------------------------------------------------------------
# Doctors 
--------------------------------------------------------------*/
.doctors {
  background: #fff;
  padding-top: 20px;
  
 
}


.doctors .doctors-title {
  text-align: center;
  padding-bottom: 30px;
}

.doctors .member {
  position: relative;
  box-shadow: 0px 2px 15px rgba(44, 73, 100, 0.08);
  padding: 30px;
  border-radius: 10px;
}

.doctors .member .pic {
  overflow: hidden;
  width: 180px;
  border-radius: 50%;
}



.doctors .doctors-title h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #2c4964;
}


