.App { 
  padding: 0px 20px;
    /* selector de clase */
    /* text-align: center; */
  
   /*  border-top: 20px solid black; */
 }
  
  
  
  /*--------------------------------------------------------------
  # Top Bar
  --------------------------------------------------------------*/
  #topbar {
    /* atributo: valor */
    background: #fff; 
    height: 40px;
    font-size: 14px;
    transition: all 0.5s;/* Permite definir la transición entre dos estados de un elemento */
    z-index: 996;/* determina el orden de los elementos posicionados en una página web */
    /* border: 5px solid blue; */
  }
  
  
  #topbar.topbar-scrolled { /* revisar si es el banner que ses oculta*/
    top: -40px;
  }
  
  
  #topbar .contacto-info a {
    line-height: 1;
    color: #444444;
    transition: 0.3s;
    text-decoration: none;
    
  }
   
  #topbar .contacto-info a:hover { /* aqui es el color del resalto  */
    color: #1977cc;
  }
  
  #topbar .contacto-info i { /* realtar ico la cual es una imagen */
    color: #1977cc;
    padding-right: 4px;
    margin-left: 15px;
    line-height: 0;
  }
  
  #topbar .contacto-info i:first-child {
    margin-left: 0;
  }
  
  #topbar .social-links a {
    color: #437099;
    padding-left: 15px;
    display: inline-block;
    line-height: 1px;
    transition: 0.3s;
  }
  
  #topbar .social-links a:hover {
    color: #1977cc;
  }
  
  #topbar .social-links a:first-child {
    border-left: 0;
  }
  
  /*--------------------------------------------------------------*/
  
  
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
    top: 40px;
    box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
   
    
  }
  
  
  #header.header-scrolled {
    top: 40;
  }
  
  #header .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0.5px;
    font-family: "Poppins", sans-serif;
    
    
  }
  
  #header .logo a {
    color: #2c4964;
    text-decoration: none;
    
  }
  
  #header .logo img { /* no hay nada */
    max-height: 40px;
  }
  
  
  
  /*--------------------------------------------------------------*/
  
  
  
  /* elementos flotantes no pueden flotar en ambos lados */
  
  .clearfix{
    clear:both; 
  }
  
  
  

  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    width: 100%;
    height: 80vh;
    background: url("assets/img/galeria/hero-bg.png") top center;
    background-size: cover;
    
    /* margin-bottom: -100px; */
    
    
  }
  
  #hero .container {
    position: relative;
  }
  
  #hero .container a {
    text-decoration: none;
  }
  #hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    text-transform: uppercase;
    color: #2c4964;
  }
  
  #hero h2 {
    color: #2c4964;
    margin: 10px 0 0 0;
    font-size: 24px;
  }
  
  #hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 35px;
    margin-top: 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: #1977cc;
  }
  
  #hero .btn-get-started:hover {
    background: #3291e6;
  }
  
  
  
  